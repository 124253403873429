@import '~bootstrap/dist/css/bootstrap.min.css';
.app-color {
    color: #F15D22;
}
.color-blue {
    color: #0783EF;
    font-weight: 500;
}
.app-background {
    background-color: #F15D22;
    color: #ffffff;
}
body {
    background-color: #f1f1f1!important;
    color: #363636;
}
.box-default {
    max-width: 1200px;
    margin: auto;
    padding: 0 15px;
}
.no-mpl {
    padding-left: 0!important;
    margin-left: 0!important;
}
.no-mpr {
    padding-right: 0!important;
    margin-right: 0!important;
}
.cl-tt {
    color: #0783EF;
    font-weight: 500;
}
.cl-content {
    color: #363636;
}
.cl-mean {
    color: #707070;
}
.cl-text {
    color: #F15D22;
}
.icon-6 {
    width: 6px;
}
.icon-8 {
    width: 8px;
}
.icon-10 {
    width: 10px;
}
.icon-12 {
    width: 12px;
}
.icon-14 {
    width: 14px;
}
.icon-13 {
    width: 13px;
}
.icon-16 {
    width: 16px;
}
.icon-18 {
    width: 18px;
}
.icon-20 {
    width: 20px;
}
.icon-22 {
    width: 22px;
}
.icon-24 {
    width: 24px;
}
.icon-26 {
    width: 26px;
}
.font-12 {
    font-size: 12px;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.font-18 {
    font-size: 18px;
}
.font-20 {
    font-size: 20px;
}
.w-100 {
    width: 100%;
}
.mt-20 {
    margin-top: 15px;
}
.dp-inblock {
    display: inline-block;
}
.hidden {
    display: none;
}
.no-margin {
    margin-left: 0;
    margin-right: 0;
}
.txt-err {
    color:#ff3300; 
    margin-top: 5px;
}
.txt-success {
    color: green;
    margin-top: 5px;
}
.btn-update {
    background-color: #0783EF;
    color: #ffffff;
}
.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #afb2b6;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}
.effect-load {
    position: absolute;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    left: 50%;
    top: 100px;
    z-index: 9999;
}
.img-circle {
    border-radius: 50%;
    border: 1px solid #989ca2;
}
.txt-word-default .txt-romaja, .box-mean .txt-romaja {
    color: #707070;
    margin-left: 16px;
    font-size: 16px;
    font-weight: 400;
}
.back-route {
    margin-bottom: 10px;
    margin-top: 10px;
    cursor: pointer;
}
.back-route img, .back img {
    margin-bottom: 2px;
    margin-right: 5px;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.content-result-white {
    background-color: white;
    border: 1px solid #E5E5E5;
    box-shadow: 0px 0px 3.66419px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}
.box-banner {
    margin-bottom: 20px;
}
.box-pre {
    position: absolute;
    top: -11px;
    right: -7px;
    -webkit-transform: rotate(38deg);
    transform: rotate(44deg);
}
.icon-pre {
    width: 16px;
    cursor: pointer;
    display: inline-block;
    border-radius: 50%;
}
.box-select-time {
    background-color: #fff;
    border-bottom: 1px solid #dcdee1;
    padding: 6px 16px;
}
.box-select-time * {
    display: inline-block;
    width: 33%;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    opacity: .7;
    height: 30px;
    line-height: 26px;
}
.box-select-time .sl-active {
    border: 1px solid #ccd0d5;
    border-radius: 5px;
}
.tt-box {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    font-size: 15px;
}
.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.tag-user {
    color: #436ab3;
}
/* search box */
.custom-search {
    height: 50px;
    position: relative;
}
.input-search::-webkit-input-placeholder, .input-search::-moz-placeholder, .input-search:-ms-input-placeholder, .input-search:-moz-placeholder {
    font-size: 16px
}
.input-search {
    z-index: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    font-size: 18px;
    border-radius: 12px;
    padding: 0px 160px 0px 24px;
    color: #333;
}
.input-search:focus {
    outline: none;
    border: 1px solid #212224;
}
.right-control-search {
    position: absolute;
    right: 0;
    top: 0;
}
.right-control-search div {
    display: inline-block;
    height: 49px;
    margin-left: 4px;
    line-height: 46px;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    margin-top: 0.5px;
}
.custom-search .btn-search {
    width: 70px;
    border-radius: 0 12px 12px 0;
    background-color: #0783EF;
}
/* search box */

.load-animation {
    -webkit-animation-duration: 1.25s;
    animation-duration: 1.25s;
    -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
        animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
    background: #f6f6f6;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #f6f6f6), color-stop(18%, #f0f0f0), color-stop(33%, #f6f6f6));
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 800px 104px;
    position: relative;
}
@media (max-width: 767px) {
    .container-1 {
        order: 1;
    }
    .container-2 {
        order: 3;
    }
    .container-3 {
        order: 2;
    }
}
@media(min-width: 990px) {
    .box-mobile {
        display: none !important;
    }
    .box-web {
        display: block;
    }
}

@media(max-width: 990px){
    .box-mobile {
        display: block;
    } 
    .box-web {
        display: none !important;
    } 
    .box-content-default {
        padding-top: 0!important;
    }
}
@media(max-width: 1024px){
    .box-default {
        max-width: 998px;
    }
}